import React from 'react'
import '../CardPages/GemGreenCerti.css'
import '../CardPages/Iso.css'

const Iso = () => {
  return (
    <div>
    <div className="GEMCP-main-div">
      <div className="GEMCPButtonDiv">
        <h2>ISO 14001 conformity</h2>
        <div className="GEMCP-btn">
          <a href="" className="GEMCP-btn-link">
            Home <span className="separator"> &gt; </span> ISO 14001 conformity
          </a>
        </div>
       
      </div>
    </div>
    <div className="GEMCP-flex-div">
    {/* <div className="important-link">
          <a href="">Important Links</a>
        </div>
        <div className="count-div">
            <h2></h2>
        </div> */}
        </div>
        <div className="Iso-container">

            <h2>ISO 14001 conformity</h2>
            </div>
            <div className="text-para-div">
           <h3>ISO 14001 Environment Management System Audits</h3>
          <p>
          Energy, Water and Environment as per ISO: 14001 
           Environmental management systems assist organisations for identifying and assessing several internal and external factors for continually improving environmental performance through a systematic evaluation of systems and processes within and external to the auditing organisations. The audit is carried out as per ISO 14001 objectives and principles.

          </p>
        </div>
        
  </div>
  )
}

export default Iso
