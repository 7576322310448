import React from 'react'
import '../CardPages/GemGreenCerti.css'
import '../CardPages/Iso.css'

const EnergyAudit = () => {
  return (
    <div>
    <div className="GEMCP-main-div">
      <div className="GEMCPButtonDiv">
        <h2>ISO 14001 conformity</h2>
        <div className="GEMCP-btn">
          <a href="" className="GEMCP-btn-link">
            Home <span className="separator"> &gt; </span> Energy Audits
          </a>
        </div>
       
      </div>
    </div>
    <div className="GEMCP-flex-div">
    {/* <div className="important-link">
          <a href="">Important Links</a>
        </div>
        <div className="count-div">
            <h2></h2>
        </div> */}
        </div>
        <div className="Iso-container">

            <h2>Energy Audits</h2>
            </div>
            <div className="text-para-div">
           {/* <h3>ISO 14001 Environment Management System Audits</h3> */}
          <p>
          In Indian context, Energy audit is a scientific method based on guidelines by Bureau of Energy Efficiency (BEE), other existing statutory codes and good energy management practices. An energy audit is a process of verifying, monitoring, and analysing the use of energy in a facility and providing recommendations for improving energy efficiency and reducing energy consumption. ASSOCHAM with its team of certified auditors offer a competitive solutions-based approach for undertaking energy audits for industry and service sectors.

          </p>
        </div>
        
  </div>
  )
}

export default EnergyAudit
