import React from 'react';
import { useParams } from 'react-router-dom';
import Img1 from '../assets/EventImg/Event-img-1.jpg';
import Img2 from '../assets/EventImg/Event-img-2.jpg';
import Img3 from '../assets/EventImg/Event-img-3.jpg';
import Img4 from '../assets/EventImg/Event-img-4.jpg';
import './EventDetails.css';

const events = [
  {
    id: 1,
    title: 'Music Concert',
    descriptions: [
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit quos id fugiat inventore modi esse eaque laboriosam aperiam est enim doloremque cumque quidem ullam ut accusamus reprehenderit fuga, illum odio.",
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, obcaecati debitis eius repellat iusto qui facilis commodi. Suscipit ut debitis eaque dolorum doloremque omnis, in numquam voluptas. Dignissimos, quia soluta.' ,
    ],
    img: Img1,
    website: 'https://www.green-assocham.com',
    contacts: [
      { name: 'Mr. Amit Kumar Sharma', phone: '93157 00465', email: 'amitkumar.sharma@assocham.com' },
      { name: 'Mr. Rohit Bhasin', phone: '7838701858', email: 'rohit.bhasin@assocham.com' },
    ],
    lists: [
      'Building Essentials',
      'Five Elements',
      'Resource Efficiency',
      'Water and Energy Conservation Measures',
      'Best Sustainability Practices',
      'Fire and Life Safety',
    ],
  },
  {
    id: 2,
    title: 'Art Exhibition',
    descriptions:  [
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit quos id fugiat inventore modi esse eaque laboriosam aperiam est enim doloremque cumque quidem ullam ut accusamus reprehenderit fuga, illum odio.",
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, obcaecati debitis eius repellat iusto qui facilis commodi. Suscipit ut debitis eaque dolorum doloremque omnis, in numquam voluptas. Dignissimos, quia soluta.'
      
    ],
    img: Img2,
    website: 'https://www.green-assocham.com',
    contacts: [
      { name: 'Mr. Amit Kumar Sharma', phone: '93157 00465', email: 'amitkumar.sharma@assocham.com' },
      { name: 'Mr. Rohit Bhasin', phone: '7838701858', email: 'rohit.bhasin@assocham.com' },
    ],
    lists: [
      'Building Essentials',
      'Five Elements',
      'Resource Efficiency',
      'Water and Energy Conservation Measures',
      'Best Sustainability Practices',
      'Fire and Life Safety',
    ],
  },
  // Add more events as needed
];

const EventDetails = () => {
  const { id } = useParams();
  const event = events.find(event => event.id === parseInt(id));

  return (
    <div className="event-details-page">
      {event && (
        <>

          <img src={event.img} alt={event.title} className="event-details-image" style={{ height: '48vh', width: '60%', objectFit: 'cover' }} />

          <div className="event-container-div">
          <h2>{event.title}</h2>
          <div className="titel-div">
          <h3>Descriptions</h3>
          {event.descriptions.map((desc, index) => (
            <p key={index}>{desc}</p>
          ))}
          </div>


          <div className="heading-div">
          <h3>Highlights</h3>
          <ul>
            {event.lists.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          </div>

          <div className="contact-div">
          
          <h3>Contact Information</h3>
          <p>For further details, please contact:</p>
          <p><strong>Website:</strong> <a href={event.website} target="_blank" rel="noopener noreferrer">{event.website}</a></p>
          {event.contacts.map((contact, index) => (
            <p key={index}>
              <strong>{contact.name}</strong> - {contact.phone}, {contact.email}
            </p>
          ))}
          </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventDetails;
