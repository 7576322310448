// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import '../EventsPage/NewUpocming.css';
// import Img1 from '../assets/EventImg/Event-img-1.jpg';
// import Img2 from '../assets/EventImg/Event-img-2.jpg';
// import Img3 from '../assets/EventImg/Event-img-3.jpg';
// import Img4 from '../assets/EventImg/Event-img-4.jpg';
// import PastEvent from '../EventsPage/PastEvent';
// import { faCalendarDay, faLocationDot } from '@fortawesome/free-solid-svg-icons';

// const UpcomingEventSilder = () => {
//   const events = [
//     {
//       id: 1,
//       title: 'Music Concert',
//       location: 'Central Park',
//       date: '2025-10-29',
//       time: '7:00 PM',
//       img: Img1,
//     },
//     {
//       id: 2,
//       title: 'Join us for an unforgettable evening of music as legendary Indian playback singer Hariharan ji celebrates his 50 years of singing',
//       location: 'Noida Sec-62',
//       date: '2025-10-20',
//       time: '10:00 AM',
//       img: Img2,
//     },
//     {
//       id: 3,
//       title: 'Trade Expo',
//       location: 'Gaur City',
//       date: '2025-10-20',
//       time: '10:00 AM',
//       img: Img3,
//     },
//     {
//       id: 4,
//       title: 'Trade Expo',
//       location: 'Gaur City',
//       date: '2025-10-25',
//       time: '10:00 AM',
//       img: Img4,
//     },
//   ];

//   const [upcomingEvents, setUpcomingEvents] = useState([]);
//   const [pastEvents, setPastEvents] = useState([]);

//   useEffect(() => {
//     const now = new Date();
//     const upcoming = events.filter(event => new Date(event.date) > now);
//     const past = events.filter(event => new Date(event.date) <= now);

//     setUpcomingEvents(upcoming);
//     setPastEvents(past);
//   }, [events]);

//   return (
//     <div>
//       {/* <div className="about-main-div">
//         <div className="about-btn">
//           <a href="" className="about-btn-link">
//             <Link to='/'>Home</Link> <span className="separator"> &gt; </span> Upcoming Event
//           </a>
//         </div>
//       </div> */}

//       <div className="upcoming-events">
//         {/* <h2>Upcoming Events</h2> */}
//         <div className="card-container">
//           {upcomingEvents.map((event) => (
//             <div className="event-card" key={event.id}>
//               <img src={event.img} alt={event.title} className="event-image" />
//               <div className="event-details">
//                 <h3>{event.title}</h3>
//                 <p><span><FontAwesomeIcon icon={faLocationDot} /></span> {event.location}</p>
//                 <p><span><FontAwesomeIcon icon={faCalendarDay} /></span> {event.date} at {event.time}</p>
//                 <div className="view-btn">
//                   <Link to={`/event/${event.id}`} target='_blank' className="view-more-button">View More</Link>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>

//         {/* Display Past Events */}
//         {pastEvents.length > 0 && (
//           <div>
//             <h2>Past Events</h2>
//             <PastEvent events={pastEvents} />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default UpcomingEventSilder;


//css
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from 'react-slick';
import './UpcomingEventSilder.css';
import Img1 from '../assets/EventImg/Event-img-1.jpg';
import Img2 from '../assets/EventImg/Event-img-2.jpg';
import Img3 from '../assets/EventImg/Event-img-3.jpg';
import Img4 from '../assets/EventImg/Event-img-4.jpg';
import PastEvent from '../EventsPage/PastEvent';
import { faCalendarDay, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 

const UpcomingEventSlider = () => {
  const events = [
    { id: 1, title: 'Music Concert', location: 'Central Park', date: '2025-10-29', time: '7:00 PM', img: Img1 },
    { id: 2, title: 'Join us for an unforgettable evening of music as legendary Indian playback singer Hariharan ji celebrates his 50 years of singing', location: 'Noida Sec-62', date: '2025-10-20', time: '10:00 AM', img: Img2 },
    { id: 3, title: 'Trade Expo', location: 'Gaur City', date: '2025-10-20', time: '10:00 AM', img: Img3 },
    { id: 4, title: 'Trade Expo', location: 'Gaur City', date: '2025-10-25', time: '10:00 AM', img: Img4 },
  ];

  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

  useEffect(() => {
    const now = new Date();
    const upcoming = events.filter(event => new Date(event.date) > now);
    const past = events.filter(event => new Date(event.date) <= now);
    setUpcomingEvents(upcoming);
    setPastEvents(past);
  }, [events]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 4 cards at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 868,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="slider-container">
      <div className="upcoming-events">
        <Slider {...settings}>
          {upcomingEvents.map((event) => (
            <div className="event-card" key={event.id}>
              <img src={event.img} alt={event.title} className="event-image" />
              <div className="event-details">
                <h3>{event.title}</h3>
                <p><span><FontAwesomeIcon icon={faLocationDot} /></span> {event.location}</p>
                <p><span><FontAwesomeIcon icon={faCalendarDay} /></span> {event.date} at {event.time}</p>
                {/* <div className="view-btn">
                  <Link to={`/event/${event.id}`} target='_blank' className="view-more-button">View More</Link>
                </div> */}
              </div>
            </div>
          
          ))}
        </Slider>

        {/* Display Past Events */}
        {pastEvents.length > 0 && (
          <div>
            <h2>Past Events</h2>
            <PastEvent events={pastEvents} />
          </div>
        )}
      </div>
      </div>
      <button className="gallery-card-btn" >
                <Link to= '/upcoming-events'>   Show All </Link> 
                </button>

    </div>
  );
};

export default UpcomingEventSlider;
