import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import GemCP from '../assets/ExcelSheet/GEMCP.xlsx';
import './ExcelUpload.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


const ExcelUpload = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQueryName, setSearchQueryName] = useState('');
  const [searchQueryEmail, setSearchQueryEmail] = useState('');
  const entriesPerPage = 20;

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(GemCP);
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onload = (e) => {
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: 'array' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const json = XLSX.utils.sheet_to_json(ws);
        setData(json);
        setFilteredData(json);
      };

      reader.readAsArrayBuffer(blob);
    };

    fetchData();
  }, []);

  // Highlight the matching text
  const highlightText = (text, query) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    return text.toString().replace(regex, '<span class="highlight">$1</span>');
  };

  // Filter data based on name and email search queries
  const filterData = () => {
    const filtered = data.filter((row) =>
      Object.values(row).some((value) =>
        (searchQueryName && value.toString().toLowerCase().includes(searchQueryName.toLowerCase())) ||
        (searchQueryEmail && value.toString().toLowerCase().includes(searchQueryEmail.toLowerCase()))
      )
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page after applying the search
  };

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);
  const currentData = filteredData.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChangeName = (e) => {
    setSearchQueryName(e.target.value);
  };

  const handleSearchChangeEmail = (e) => {
    setSearchQueryEmail(e.target.value);
  };

  const handleSearchClick = () => {
    filterData();
  };

  // Pagination Logic
  const paginationButtons = () => {
    const buttons = [];
    const maxVisible = 3; // Number of page buttons to show (before "..." and "last")

    if (currentPage > 2) {
      buttons.push('first');
    }

    for (let i = Math.max(1, currentPage - 1); i <= Math.min(totalPages, currentPage + 1); i++) {
      if (!buttons.includes(i)) {
        buttons.push(i);
      }
    }

    if (currentPage + maxVisible < totalPages - 1) {
      buttons.push('...');
    }

    if (currentPage < totalPages - 1) {
      buttons.push('last');
    }

    return buttons;
  };

  return (
    <div className='certified-bkg'>
      <div className="about-main-div">
        <div className="about-btn">
          <a href="" className="about-btn-link">
            Home <span className="separator"> &gt; </span> GEM CPs
          </a>
        </div>
      </div>



      <div className="mainDivExcel">
        <div className="excel-bkg-div">
          <div className="search-bar">
            <FontAwesomeIcon icon={faSearch} className="excel-icon" />
            <input
              id="excel-input"
              type="text"
              value={searchQueryName}
              onChange={handleSearchChangeName}
              placeholder="Search by Name"
              autoFocus
            />
             <FontAwesomeIcon icon={faSearch} className="excel-icon" />
            <input
              id="excel-input"
              type="text"
              value={searchQueryEmail}
              onChange={handleSearchChangeEmail}
              placeholder="Search by Email"
              autoFocus
            />
            <button className="excelApplyBtn" onClick={handleSearchClick}>Apply</button>
          </div>

          <div className="excel-upload">
            {currentData.length > 0 && (
              <>
                <table className="data-table">
                  <thead>
                    <tr>
                      {Object.keys(data[0]).map((key) => (
                        <th key={key}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((row, index) => (
                      <tr key={index}>
                        {Object.values(row).map((value, i) => (
                          <td
                            key={i}
                            dangerouslySetInnerHTML={{
                              __html: highlightText(value, searchQueryName || searchQueryEmail)
                            }}
                          />
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Pagination Controls */}
                <div className="pagination">
                  {paginationButtons().map((page, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        if (page === 'first') {
                          handlePageChange(1);
                        } else if (page === 'last') {
                          handlePageChange(totalPages);
                        } else {
                          handlePageChange(page);
                        }
                      }}
                      className={`page-button ${currentPage === page ? 'active' : ''}`}
                    >
                      {page}
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExcelUpload;
