import React from 'react';
// import './Statistics1.css'; // Import the CSS file
import './StatisticsNew.css';

// Import your images
import participantsImg from '../assets/statics/Participants.png';
import professionalImg from '../assets/statics/Professional.png';
import certifiedImg from '../assets/statics/Certified Professional.png';
import conferenceImg from '../assets/statics/webinar.png';
import institutionalImg from '../assets/statics/InstitutionalMOU.png';
import knowledgeImg from '../assets/statics/knowledgeConsulting.png';
import professionalMouImg from '../assets/statics/Professional.png';

const Statistics1 = () => {
    return (
        <div className="statistics-container">
            <div className="statitem">
                <div className="stat-icon">
                    <img src={participantsImg} alt="Participants" />
                </div>
                <div className="stat-text">
                    <span className="stat-number">150,000+</span>
                    <span className="stat-label">Participants</span>
                </div>
            </div>
            {/* <div className="stat-item"> */}
            <div className="statitem">
                <div className="stat-icon">
                    <img src={professionalImg} alt="Professional" />
                </div>
                <div className="stat-text">
                    <span className="stat-number">2,000+</span>
                    <span className="stat-label">Professional</span>
                </div>
            </div>
            {/* <div className="stat-item"> */}
            <div className="statitem">
                <div className="stat-icon">
                    <img src={certifiedImg} alt="Certified Professional" />
                </div>
                <div className="stat-text">
                    <span className="stat-number">1,500+</span>
                    <span className="stat-label">Certified Professional</span>
                </div>
            </div>
            {/* <div className="stat-item"> */}
            <div className="statitem">
                <div className="stat-icon">
                    <img src={conferenceImg} alt="Conference & Webinar" />
                </div>
                <div className="stat-text">
                {/* <div className="statitem"> */}
                    <span className="stat-number">200+ </span>
                    <span className="stat-label">Conference & Webinar</span>
                </div>
            </div>
            {/* <div className="stat-item"> */}
            <div className="statitem">
                <div className="stat-icon">
                    <img src={institutionalImg} alt="Institutional MOU" />
                </div>
                <div className="stat-text">
                    <span className="stat-number">100+</span>
                    <span className="stat-label">Institutional MOU</span>
                </div>
            </div>
            {/* <div className="stat-item"> */}
            <div className="statitem">
                <div className="stat-icon">
                    <img src={knowledgeImg} alt="Knowledge Consulting Partners" />
                </div>
                <div className="stat-text">
                    <span className="stat-number">30+</span>
                    <span className="stat-label">Knowledge Consulting Partners</span>
                </div>
            </div>
            {/* <div className="stat-item"> */}
            <div className="statitem">
                <div className="stat-icon">
                    <img src={professionalMouImg} alt="Professional MOU" />
                </div>
                <div className="stat-text">
                    <span className="stat-number">10+</span>
                    <span className="stat-label">Professional MOU</span>
                </div>
            </div>
        </div>
    );
};

export default Statistics1;
