import React from 'react'

const Services = () => {
  return (
    <div>
      kya haal console
      <p>
        lorem
      </p>
    </div>
  )
}

export default Services
