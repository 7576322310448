// App.js
import React, { useState, useEffect } from 'react';
import Loading from './componant/Loading';
// import YourMainComponent from './YourMainComponent';
import App from './App'

const App1 = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading for 2 seconds or fetch data
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust this for actual data loading as needed

    return () => clearTimeout(timer); // Clean up timer
  }, []);

  return (
    <>
      {loading ? <Loading /> : <App />}
    </>
  );
};

export default App1;
