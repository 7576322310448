// GallerySlider.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './GallerySlider.css';

const GallerySlider = () => {
    const cards = [
        { id: 1, title: 'Suparshwanath, Pune', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-8.webp' },
        { id: 2, title: 'Shraddha CHS Ltd., Navi Mumbai', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-9.webp' },
        { id: 3, title: 'Alaya Apartment, Jaipur', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-1.webp' },
        { id: 4, title: 'PPI System, Vatva, Ahmedabad', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-4.webp' },
        { id: 5, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-5.webp' },
        { id: 6, title: '122, Wakad, Commercial Project, Pune', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-6.webp' },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div>
        <div className="gallery-slider">
            <Slider {...settings}>
                {cards.map((card) => (
                    <div className="slider-card" key={card.id}>
                        <img src={card.imgSrc} alt={card.title} />
                        <h3>{card.title}</h3>
                    </div>
                ))}
            </Slider>
        </div>
        <button className="gallery-card-btn" >
                <Link to= '/gemGreen'>   Show All </Link> 
                </button>
</div>
    );
};

export default GallerySlider;
