import React from 'react';
import './Story.css'; // Importing the CSS file for styles


const SuccessStories = () => {
  const images = [
    {imgSrc: 'storyImg/success-stories-1.jpeg' },
    {imgSrc: 'storyImg/success-stories-2.jpeg' },
    {imgSrc: 'storyImg/success-stories-3.jpeg' },
    {imgSrc: 'storyImg/success-stories-4.jpeg' },
    {imgSrc: 'storyImg/success-stories-5.jpeg' },
    {imgSrc: 'storyImg/success-stories-6.jpeg' },
  ];

  return (
    <div className="mainDiv">
    <div className="success-stories">
      <h1 className="heading">Success Stories</h1>
      <div className="image-container">
        {images.map((card, index) => (
          <div className="image-wrapper" key={index}>
            <img src={require(`../assets/${card.imgSrc}`)} alt={`Sustainability Goal ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default SuccessStories;
