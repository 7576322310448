import React, { useState } from 'react';
import './ContactUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Validate phone number
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validatePhone = (phone) => {
        const phonePattern = /^\d{10}$/; // Must be 10 digits
        const invalidPatterns = ['1234567890', '0987654321'];
        return phonePattern.test(phone) && !invalidPatterns.includes(phone);
    };
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log('Form submitted:', formData);
    //     alert('Form submitted successfully!');
    //     setFormData({ name: '', email: '', phone: '', subject: '', message: '' });

const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePhone(formData.phone)) {
        toast.error('Your Phone Number is Wrong');
        return;
    }


    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, formData);
        // alert('Form submitted successfully!');
        toast.success(response.data.message || 'Your Data is submitted successfully!');
        
        setFormData({ name: '', email: '', phone: '', subject: '', message: '' });
    } catch (error) {
        console.error('Error submitting the form:', error);
        // alert('Failed to submit the form. Please try again later.');
        toast.error('Error submitting form. Please try again.');
    }
};
    // };

    return (
        <div className="contact-us-container">
            <div className="contactUs-main-div">
                <div className="contactUsButtonDiv">
                    <h2>Contact Us</h2>
                    <div className="contactUs-btn">
                        <a href="" className="contactUs-btn-link">
                            Home <span className="separator"> &gt; </span> Contact Us
                        </a>
                    </div>
                </div>
            </div>

            <div className="cards-container-contact">

                <div className="card-contact">
                        <div className="icon-div">
                    {/* <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" /> */}
                    <FontAwesomeIcon icon={faLocationDot} size='3x'/>
                    </div>
                    <div className="contact-card-text">
                    <h3>Office Location</h3>
                    <p>4th Floor, YMCA Cultural Centre and Library Building, 01, Jai Singh Road, New Delhi - 110001</p>
                    </div>
                </div>

                <div className="card-contact">
                <div className="icon-div">
                    <FontAwesomeIcon icon={faEnvelope} size="3x" />
                    
                    </div>
                    <div className="contact-card-text">
                    <h3>Company Email</h3>
                    <p>amitkumar.sharma@assocham.com</p>
                    <p>example@gmail.com</p>
                    </div>
                </div>

                <div className="card-contact">
                <div className="icon-div">
                    <FontAwesomeIcon icon={faPhone} size="3x" />
                    </div>
                    <div className="contact-card-text">
                    <h3>Contact Us</h3>
                    <p>+91 9315700465</p>
                    <p>+91 1230456789</p>
                    </div>
                </div>
            </div>

            <div className="contactUs-form-main-div">
            <div className="contact-form-div">
                <h2>Get in Touch</h2>
                <form className="contactUs-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <div className="name-email-div">
                            <label id='contact'>
                                
                                <input
                                    type="text"
                                    name="name"
                                    placeholder='Your Name'
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label >
                                
                                <input
                                    type="email"
                                    name="email"
                                    placeholder='Email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="phone-subject-div">
                            <label>
                               
                                <input
                                    type="tel"
                                    name="phone"
                                    placeholder='Phone'
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                            
                                <input
                                    type="text"
                                    name="subject"
                                    placeholder='Subject'
                                    value={formData.subject}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>
                    </div>
                    <div className="message-div">
                        
                            
                            <textarea
                                name="message"
                                placeholder='Message'
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                        
                    </div>
                    <button className='submit-btn' type="submit">Submit</button>
                </form>
            </div>
            </div>

            <div className="location">
                <div className="map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.1076960376777!2d77.21050607652033!3d28.626534184351243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1d598c71ccbd%3A0x2f75120924969b5!2sASSOCHAM%20GEM%20Green%20Building%20Council!5e0!3m2!1sen!2sin!4v1727242995646!5m2!1sen!2sin"
                        width="100%"
                        height="600"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ContactUs;
