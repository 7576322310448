import React from 'react';
import './StatisticsNew.css'; // Import the CSS file

// Import your images
import participantsImg from '../assets/statics/Participants.png';
import professionalImg from '../assets/statics/Professional.png';
import certifiedImg from '../assets/statics/Certified Professional.png';
import conferenceImg from '../assets/statics/webinar.png';
import institutionalImg from '../assets/statics/InstitutionalMOU.png';
import knowledgeImg from '../assets/statics/knowledgeConsulting.png';
import professionalMouImg from '../assets/statics/Professional.png';

const Statistics1 = () => {
    const statistics = [
        { imgSrc: participantsImg, number: '150,000+', label: 'Participants' },
        { imgSrc: professionalImg, number: '2,000+', label: 'Professional' },
        { imgSrc: certifiedImg, number: '1,500+', label: 'Certified Professional' },
        { imgSrc: conferenceImg, number: '200+', label: 'Conference & Webinar' },
        { imgSrc: institutionalImg, number: '100+', label: 'Institutional MOU' },
        { imgSrc: knowledgeImg, number: '30+', label: 'Knowledge Consulting Partners' },
        { imgSrc: professionalMouImg, number: '10+', label: 'Professional MOU' },
    ];

    return (
        <div className="statics-main-div">
        <ul className="statistics-container">
            {statistics.map((stat, index) => (
                <li className="statitem" key={index}>
                    <div className="stat-icon">
                        <img src={stat.imgSrc} alt={stat.label} />
                    </div>
                    <div className="stat-text">
                        <span className="stat-number">{stat.number}</span>
                        <span className="stat-label">{stat.label}</span>
                    </div>
                </li>
            ))}
        </ul>
        </div>
    );
};

export default Statistics1;
