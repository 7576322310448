import React, { useEffect, useRef, useState } from 'react';
import './StepNew.css';

const steps = [
  { number: '01', title: 'Registration', icon: '📝' },
  { number: '02', title: 'Online Payment', icon: '💳' },
  { number: '03', title: 'Schedule Your Test', icon: '📅' },
  { number: '04', title: 'Take the online test as per Schedule', icon: '🖥️' },
  { number: '05', title: 'Download Reference Guide', icon: '📕' },
];

const RegistrationSteps = () => {
  const stepRefs = useRef([]);
  const [scrollY, setScrollY] = useState(0);
  const [isScrollingAllowed, setIsScrollingAllowed] = useState(true);  // Track scroll permission
  const scrollContainerRef = useRef(null);  // Ref for the scrollable container

  // Scroll handler to slow down the scroll speed for a specific container
  const handleWheel = (e) => {
    if (!isScrollingAllowed) {
      e.preventDefault(); // Prevent default scroll behavior if scrolling is disabled
    } else if (scrollContainerRef.current && scrollContainerRef.current.contains(e.target)) {
      e.preventDefault(); // Prevent default scroll behavior on this container

      // Slow down the scroll delta (make it less than the default delta)
      const newScrollY = window.scrollY + e.deltaY * 0.2; // Adjust factor to control speed
      window.scrollTo(0, newScrollY); // Apply manual scroll position
    }
  };

  // Intersection Observer to handle animations when steps come into view
  useEffect(() => {
    const observerOptions = {
      threshold: 0.5, // Trigger when 50% of the element is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        const stepIndex = stepRefs.current.indexOf(entry.target);

        if (entry.isIntersecting) {
          // Disable scrolling while animation is happening
          setIsScrollingAllowed(false);
          entry.target.classList.add('visible');
          
          // Once animation ends, allow scrolling again and update the border
          setTimeout(() => {
            entry.target.classList.add('completed'); // Mark step as completed
            setIsScrollingAllowed(true); // Enable scrolling again
          }, 600);  // Assuming the animation lasts 3 seconds
        } else {
          entry.target.classList.remove('visible');
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    stepRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
        console.log('Observing:', ref); // Debug: Confirm each element is observed
      }
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    // Add event listener to slow down scroll only on the specific element
    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('wheel', handleWheel);
    };
  }, [isScrollingAllowed]); // Re-run the effect whenever scroll permission changes

  return (
    // <div className="registration-step-bg">
      <div className="registration-steps" ref={scrollContainerRef}>
        {/* <div className="registratin-circleBox"> */}
            
          <div className="circle">
            <h2>Registration Steps
              {/* <br />Certified Professionals */}
              </h2>

           
            
            {steps.map((step, index) => (
              <div
                key={index}
                ref={(el) => (stepRefs.current[index] = el)}
                className={`step step-${index + 1} fade-in-step`}
              >
                <span className="icon">{step.icon}</span>
                <span className="text">
                  <span className="number">{step.number}. </span>
                  {step.title}
                </span>
                
                <div className={`stepcir-${index + 1}`}></div>
              </div>
            ))}
           
            {steps.map((step, index) => (
              <div key={index} className={`stepArrow-${index + 1}`}></div>
            ))}
          </div>
        {/* </div> */}
      </div>
    // </div>
  );
};

export default RegistrationSteps;
