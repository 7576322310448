// PastEvent.jsx
import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import upcomingEvents from './UpcomingEvent'


const PastEvent = ({ events = [] }) => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

  useEffect(() => {
    const now = new Date();
    const upcoming = events.filter(event => new Date(event.date) > now);
    const past = events.filter(event => new Date(event.date) <= now);

    setUpcomingEvents(upcoming);
    setPastEvents(past);
  }, [events]);
  return (

    <div>
       <div className="about-btn">
          <a href="" className="about-btn-link">
            <Link to='/'>Home</Link> <span className="separator"> &gt; </span> Upcoming Event
          </a>
        </div>


    

    <div className="past-events">
      <h2>Past Events</h2>
      {events.length > 0 ? (
        events.map((event) => (
          <div className="event-card" key={event.id}>
            <img src={event.img} alt={event.title} className="event-image" />
            <div className="event-details">
              <h3>{event.title}</h3>
              <p>
                <span><FontAwesomeIcon icon={faLocationDot} /></span> {event.location}
              </p>
              <p>
                <span><FontAwesomeIcon icon={faCalendarDay} /></span> {event.date} at {event.time}
              </p>
              <div className="view-btn">
                <Link to={`/event/${event.id}`} target='_blank' className="view-more-button">View More</Link>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No past events available.</p>
      )}
      <div className="card-container">
          {upcomingEvents.map((event) => (
            <div className="event-card" key={event.id}>
              <img src={event.img} alt={event.title} className="event-image" />
              <div className="event-details">
                <h3>{event.title}</h3>
                <p><span><FontAwesomeIcon icon={faLocationDot} /></span> {event.location}</p>
                <p><span><FontAwesomeIcon icon={faCalendarDay} /></span> {event.date} at {event.time}</p>
                <div className="view-btn">
                  <Link to={`/event/${event.id}`} target='_blank' className="view-more-button">View More</Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Display Past Events */}
        {pastEvents.length > 0 && (
          <div>
            <h2>Past Events</h2>
            <upcomingEvents events={pastEvents} />
          </div>
        )}
      {/* </div> */}


    </div>
    </div>

  );
};

export default PastEvent;
