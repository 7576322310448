// import React, { useState } from 'react';
// import Gallery from './Gallery';
// import ResourceSlider from './ResourceSlider';
// import './SliderMainPage.css';
// import Resource from '../componant/Resource';

// const SliderMainpage = () => {
//   const [activeOption, setActiveOption] = useState('GEM Green Certification');

//   const getContent = (option) => {
//     switch (option) {
//       case 'GEM Green Certification':
//         return <Gallery />;
//       case 'GEM Eco Product':
//         return 'Details on GEM Eco Products...';
//       case 'Resource':
//         return <ResourceSlider />;
//     //   case 'Gallery':
//     //     return 'A gallery showcasing our projects...';
//     //   default:
//     //     return 'Select an option to see the content.';
//     }
//   };

//   const handleOptionChange = (option) => {
//     setActiveOption(option);
//   };

//   return (
//     <div className="slider-mainpage">
//       {/* <h2>Select an Option:</h2> */}
//       <div className="button-group">
//         {['GEM Green Certification', 'GEM Eco Product', 'Resource'].map((option) => (
//           <button key={option} onClick={() => handleOptionChange(option)}>
//             {option}
//           </button>
//         ))}
//       </div>
//       <div className="content">
//         {/* <h2>{activeOption}</h2> */}
//         <p>{getContent(activeOption)}</p>
//       </div>
//     </div>
//   );
// };

// export default SliderMainpage;


//new code
import React, { useState, useEffect, useRef } from 'react';
import Gallery from './Gallery';
import ResourceSlider from './ResourceSlider';
import './SliderMainPage.css';
import Resource from '../componant/Resource';
import UpcomingEvent from '../EventsPage/UpcomingEvent';
import UpcomingEventSilder from './UpcomingEventSilder';
import SliderEcoProduct from './SliderEcoProduct';
import GallerySlider from './GallerySlider';

const SliderMainpage = () => {
  const [activeOption, setActiveOption] = useState('GEM Green Certification');
  const [arrowPosition, setArrowPosition] = useState(0);
  const buttonRefs = useRef({});

  const getContent = (option) => {
    switch (option) {
      case 'GEM Green Certification':
        return <GallerySlider />;
        // return <Gallery />;
      case 'GEM Eco Product':
        return <SliderEcoProduct />;
      case 'Resource':
        return <ResourceSlider />;
        case 'Upcoming Events':
          return <UpcomingEventSilder />
      default:
        return 'Select an option to see the content.';
    }
  };

  const handleOptionChange = (option) => {
    setActiveOption(option);
  };

  useEffect(() => {
    const activeButton = buttonRefs.current[activeOption];
    if (activeButton) {
      const { offsetLeft, offsetWidth } = activeButton;
      setArrowPosition(offsetLeft + offsetWidth / 2);
    }
  }, [activeOption]);

  return (
    <div className="slider-mainpage">
      <div className="button-group">
        {['GEM Green Certification', 'GEM Eco Product', 'Resource', 'Upcoming Events'].map((option) => (
          <button
            key={option}
            className={`button ${activeOption === option ? 'active' : ''}`}
            onClick={() => handleOptionChange(option)}
            ref={(el) => (buttonRefs.current[option] = el)} // Save reference to button
          >
            {option}
          </button>
        ))}
        <style>
          {`
            .button-group::after {
              left: ${arrowPosition}px; /* Use dynamic arrow position */
            }
          `}
        </style>
      </div>
      <div className="content">
        <p>{getContent(activeOption)}</p>
      </div>
    </div>
  );
};

export default SliderMainpage;
