
// import React, { useState } from "react";
// import India from "@svg-maps/india";
// import { SVGMap } from "react-svg-map";
// import "react-svg-map/lib/index.css";
// import './NewGeoEcoSystemcolor.css'
// import './NewGeoEcoSystem.css'; // Import the CSS file for styling

// // Color mapping for each state
// const stateColors = {
//   "Uttar Pradesh": "#FF6347", // Tomato color
//   "Maharashtra": "#32CD32",   // Lime Green color
//   "Karnataka": "#1E90FF",     // Dodger Blue
//   "Gujarat": "#FFD700",       // Gold
//   "Bihar": "#00FA9A",         // Medium Spring Green
//   "West Bengal": "#8A2BE2",   // Blue Violet
//   "Rajasthan": "#FF4500",     // Orange Red
//   "Kerala": "#FF69B4",        // Hot Pink
//   "Andhra Pradesh": "#6A5ACD", // Slate Blue
//   "Haryana": "#DC143C",       // Crimson
//   "Punjab": "#008B8B",        // Dark Cyan
//   "Uttarakhand": "#A52A2A",   // Brown
//   "Himachal Pradesh": "#C71585", // Medium Violet Red
//   "Jammu and Kashmir": "#8B0000", // Dark Red
//   "Arunachal Pradesh": "#7FFF00", // Chartreuse
//   "Nagaland": "#800000",      // Maroon
//   "Madhya Pradesh": "#FF6347", // Tomato
//   "Chhattisgarh": "#FF6347",  // Tomato
//   "Odisha": "#FF6347",        // Tomato
//   "Assam": "#7FFF00",         // Chartreuse
//   "Jharkhand": "#32CD32",     // Lime Green
//   "Meghalaya": "#00FA9A",     // Medium Spring Green
//   "Manipur": "#7FFF00",       // Chartreuse
//   "Mizoram": "#1E90FF",       // Dodger Blue
//   "Sikkim": "#32CD32",        // Lime Green
//   "Tripura": "#1E90FF",       // Dodger Blue
//   "Goa": "#FFD700",           // Gold
//   "Delhi": "#DC143C",         // Crimson
//   "Puducherry": "#1E90FF",    // Dodger Blue
//   "Andaman and Nicobar Islands": "#4682B4", // Steel Blue
//   "Lakshadweep": "#00FA9A",   // Medium Spring Green
//   "Daman and Diu": "#1E90FF", // Dodger Blue
//   "Dadra and Nagar Haveli": "#FFD700", // Gold
//   "Chandigarh": "#32CD32",    // Lime Green
//   "Ladakh": "#8B0000",        // Dark Red
// };

// // Example data for selected state details
// const stateData = {
//   "Uttar Pradesh": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/ANUPAM-MITTAL.webp", 
//     chairManName: "Ar Anupam Mittal", 
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
//     coChairManName: "Ar. Anurag Bajpai" 
//   },
//   "Maharashtra": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/user-1.webp", 
//     chairManName: "Ar. Vilas Avachat", 
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Roshni-Udyavar-Yehuda.webp", 
//     coChairManName: "Ar. Roshni Udyavar Yehuda" 
//   },
//   "Chhattisgarh": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/NAVIN-SHARMA.webp", 
//     chairManName: "Ar. Navin Sharma", 
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Raj-Prajapati.webp", 
//     coChairManName: " Ar. Raj Prajapati" 
//   },
//   "Gujarat": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/ANAND-TATU.webp", 
//     chairManName: " Ar. Anand Tatu", 
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Lokendra-Balasaria.webp", 
//     coChairManName: " Ar. Lokendra Balasariya" 
//   },
//   "Haryana": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/PUNIT-SETHI.webp", 
//     chairManName: " Ar. Punit Sethi", 
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
//     coChairManName: " Mr. Sanjay Varshney" 
//   },
//   "Jharkhand": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/ARUN-KUMAR.webp", 
//     chairManName: "Ar. Arun Kumar", 
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
//     coChairManName: " " 
//   },
//   "Karnataka": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/LEENA-KUMAR.webp", 
//     chairManName: "Ar. Leena Kumar", 
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Bindi-Saolapurkar.webp", 
//     coChairManName: " Ar. Bindi Saolapurkar" 
//   },
//   "Kerala": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/SUDHIR-BALAKRISHNAN.webp", 
//     chairManName: "Ar. Sudhir Balakrishnan", 
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
//     coChairManName: " Mr. Shreeganesh V Nair" 
//   },
//   "Odisha": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/SOUMYENDU-S-RAY.webp", 
//     chairManName: "Ar. (Prof.) S. S. Ray ", 
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Sangram-Mohanty.webp", 
//     coChairManName: " Prof. Sangram Mohanty" 
//   },
//   "Punjab": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/SURINDER-BAHGA-1.webp", 
//     chairManName: "Ar. Surinder Bahga ", 
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Sangram-Mohanty.webphttps://zen360.top/gem-green/wp-content/uploads/2023/12/Balkar-Singh.webp", 
//     coChairManName: " Ar. Balkar Singh" 
//   },
//   "Rajasthan": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/TUSHAR-SOGANI.webp", 
//     chairManName: " Ar. Tushar Sogani ", 
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Gaurav-Agrawal.webp", 
//     coChairManName: " Ar. Gaurav Agrawal" 
//   },
//   "West Bengal": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/DEBATOSH-SAHU.webp", 
//     chairManName: "Ar. Debatosh Sahu", 
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Abin-Chaudhuri.webp", 
//     coChairManName: "  Ar. Abin Chaudhuri" 
//   },
  
// };

// function NewGeoEcoSystem() {
//   const [selectedState, setSelectedState] = useState(null);
//   const [hoveredState, setHoveredState] = useState(null);
//   const [hoverPosition, setHoverPosition] = useState({ x: 1, y: 0 });

//   const onLocationClick = (event) => {
//     const stateName = event.target.getAttribute("name");
    
//     if (stateData[stateName]) {
//       setSelectedState({ name: stateName, ...stateData[stateName] });

//       // Trigger state data animation after a short delay
//       setTimeout(() => {
//         const card = document.querySelector('.map-detail-container');
//         if (card) {
//           card.style.animation = 'slideUp 0.9s forwards';
//         }
//       }, 100);
//     } else {
//       setSelectedState(null);
//     }
//   };

//   const onLocationHover = (event) => {
//     const stateName = event.target.getAttribute("name");
//     setHoveredState(stateName);

//     const rect = event.target.getBoundingClientRect();
//     setHoverPosition({
//       x: rect.left + rect.width / 1.5,
//       y: rect.top + rect.height / 0.9,
//     });
//   };

//   const onLocationLeave = () => {
//     setHoveredState(null);
//   };

//   const locationClassName = (location) => {
//     const stateName = location.name;
//     if (stateColors[stateName]) {
//       return `state-${stateName.replace(/\s+/g, '-').toLowerCase()}`;
//     }
//     return "state-default";
//   };

//   return (
//     <div>
//       <div className="GemChapter-bkg">
//         <div className="about-main-div">
//           <div className="about-btn">
//             <a href="" className="about-btn-link">
//               Home <span className="separator"> &gt; </span> GEM Chapters
//             </a>
//           </div>
//         </div>

//         <div className="main-div-of-map">
//           <div className="map-main-div">
//             <div className="btn-div-map">
//               {selectedState && (
//                 <div className="map-detail-container">
//                   <div className="map-cardHeading">
//                     <h3 className="state-name">{selectedState.name}</h3>
//                   </div>
//                   <div className="card-map-main">
//                     <div className="map-detail-card">
//                       <img src={selectedState.chairMan} alt="Chair Man" className="map-detail-img" />
//                       <p className="ecoList">{selectedState.chairManName}</p>
//                       <p className="ecoList">Chair</p>
//                     </div>

//                     <div className="map-detail-card">
//                       <img src={selectedState.coChairMan} alt="Co-Chair Man" className="map-detail-img" />
//                       <p className="ecoList">{selectedState.coChairManName}</p>
//                       <p className="ecoList">Co-Chair</p>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </div>

//             <div className="map-container">
//               <SVGMap
//                 map={India}
//                 onLocationClick={onLocationClick}
//                 onLocationMouseOver={onLocationHover}
//                 onLocationMouseOut={onLocationLeave}
//                 locationClassName={locationClassName} // Apply dynamic class
//               />

//               {hoveredState && (
//                 <div className="hover-info" style={{ left: hoverPosition.x, top: hoverPosition.y }}>
//                   {hoveredState}
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default NewGeoEcoSystem;

//new code 
import React, { useState } from "react";
import India from "@svg-maps/india";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import './NewGeoEcoSystemcolor.css';
import './NewGeoEcoSystem.css'; // Import the CSS file for styling

// Color mapping for each state
const stateColors = {
  "Uttar Pradesh": "#FF6347", // Tomato color
  "Maharashtra": "#32CD32",   // Lime Green color
  "Karnataka": "#1E90FF",     // Dodger Blue
  "Gujarat": "#FFD700",       // Gold
  "Bihar": "#00FA9A",         // Medium Spring Green
  "West Bengal": "#8A2BE2",   // Blue Violet
  "Rajasthan": "#FF4500",     // Orange Red
  "Kerala": "#FF69B4",        // Hot Pink
  "Andhra Pradesh": "#6A5ACD", // Slate Blue
  "Haryana": "#DC143C",       // Crimson
  "Punjab": "#008B8B",        // Dark Cyan
  "Uttarakhand": "#A52A2A",   // Brown
  "Himachal Pradesh": "#C71585", // Medium Violet Red
  "Jammu and Kashmir": "#8B0000", // Dark Red
  "Arunachal Pradesh": "#7FFF00", // Chartreuse
  "Nagaland": "#800000",      // Maroon
  "Madhya Pradesh": "#FF6347", // Tomato
  "Chhattisgarh": "#FF6347",  // Tomato
  "Odisha": "#FF6347",        // Tomato
  "Assam": "#7FFF00",         // Chartreuse
  "Jharkhand": "#32CD32",     // Lime Green
  "Meghalaya": "#00FA9A",     // Medium Spring Green
  "Manipur": "#7FFF00",       // Chartreuse
  "Mizoram": "#1E90FF",       // Dodger Blue
  "Sikkim": "#32CD32",        // Lime Green
  "Tripura": "#1E90FF",       // Dodger Blue
  "Goa": "#FFD700",           // Gold
  "Delhi": "#DC143C",         // Crimson
  "Puducherry": "#1E90FF",    // Dodger Blue
  "Andaman and Nicobar Islands": "#4682B4", // Steel Blue
  "Lakshadweep": "#00FA9A",   // Medium Spring Green
  "Daman and Diu": "#1E90FF", // Dodger Blue
  "Dadra and Nagar Haveli": "#FFD700", // Gold
  "Chandigarh": "#32CD32",    // Lime Green
  "Ladakh": "#8B0000",        // Dark Red
};

// Example data for selected state details
const stateData = {
    "Uttar Pradesh": { 
      chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/ANUPAM-MITTAL.webp", 
      chairManName: "Ar Anupam Mittal", 
      coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
      coChairManName: "Ar. Anurag Bajpai" 
    },
    "Maharashtra": { 
      chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/user-1.webp", 
      chairManName: "Ar. Vilas Avachat", 
      coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Roshni-Udyavar-Yehuda.webp", 
      coChairManName: "Ar. Roshni Udyavar Yehuda" 
    },
    "Chhattisgarh": { 
      chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/NAVIN-SHARMA.webp", 
      chairManName: "Ar. Navin Sharma", 
      coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Raj-Prajapati.webp", 
      coChairManName: " Ar. Raj Prajapati" 
    },
    "Gujarat": { 
      chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/ANAND-TATU.webp", 
      chairManName: " Ar. Anand Tatu", 
      coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Lokendra-Balasaria.webp", 
      coChairManName: " Ar. Lokendra Balasariya" 
    },
    "Haryana": { 
      chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/PUNIT-SETHI.webp", 
      chairManName: " Ar. Punit Sethi", 
      coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
      coChairManName: " Mr. Sanjay Varshney" 
    },
    "Jharkhand": { 
      chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/ARUN-KUMAR.webp", 
      chairManName: "Ar. Arun Kumar", 
      coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
      coChairManName: " " 
    },
    "Karnataka": { 
      chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/LEENA-KUMAR.webp", 
      chairManName: "Ar. Leena Kumar", 
      coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Bindi-Saolapurkar.webp", 
      coChairManName: " Ar. Bindi Saolapurkar" 
    },
    "Kerala": { 
      chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/SUDHIR-BALAKRISHNAN.webp", 
      chairManName: "Ar. Sudhir Balakrishnan", 
      coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
      coChairManName: " Mr. Shreeganesh V Nair" 
    },
    "Odisha": { 
      chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/SOUMYENDU-S-RAY.webp", 
      chairManName: "Ar. (Prof.) S. S. Ray ", 
      coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Sangram-Mohanty.webp", 
      coChairManName: " Prof. Sangram Mohanty" 
    },
    "Punjab": { 
      chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/SURINDER-BAHGA-1.webp", 
      chairManName: "Ar. Surinder Bahga ", 
      coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Sangram-Mohanty.webphttps://zen360.top/gem-green/wp-content/uploads/2023/12/Balkar-Singh.webp", 
      coChairManName: " Ar. Balkar Singh" 
    },
    "Rajasthan": { 
      chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/TUSHAR-SOGANI.webp", 
      chairManName: " Ar. Tushar Sogani ", 
      coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Gaurav-Agrawal.webp", 
      coChairManName: " Ar. Gaurav Agrawal" 
    },
    "West Bengal": { 
      chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/DEBATOSH-SAHU.webp", 
      chairManName: "Ar. Debatosh Sahu", 
      coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Abin-Chaudhuri.webp", 
      coChairManName: "  Ar. Abin Chaudhuri" 
    },
    
  // Add other states here...
};

function NewGeoEcoSystem() {
  const [selectedState, setSelectedState] = useState(null);
  const [hoveredState, setHoveredState] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ x: 1, y: 0 });

  const onLocationClick = (event) => {
    const stateName = event.target.getAttribute("name");

    if (stateData[stateName]) {
      setSelectedState({ name: stateName, ...stateData[stateName] });

      // Trigger state data animation after a short delay
      setTimeout(() => {
        const card = document.querySelector('.map-detail-container');
        if (card) {
          card.style.animation = 'slideUp 0.9s forwards';
        }
      }, 100);
    } else {
      setSelectedState(null);
    }
  };

  const onLocationHover = (event) => {
    const stateName = event.target.getAttribute("name");
    setHoveredState(stateName);

    const rect = event.target.getBoundingClientRect();
    setHoverPosition({
      x: rect.left + rect.width / 1.5,
      y: rect.top + rect.height / 0.9,
    });
  };

  const onLocationLeave = () => {
    setHoveredState(null);
  };

  const onButtonClick = (stateName) => {
    if (stateData[stateName]) {
      setSelectedState({ name: stateName, ...stateData[stateName] });

      // Trigger state data animation after a short delay
      setTimeout(() => {
        const card = document.querySelector('.map-detail-container');
        if (card) {
          card.style.animation = 'slideUp 0.9s forwards';
        }
      }, 100);
    }
  };

  const locationClassName = (location) => {
    const stateName = location.name;
    if (stateColors[stateName]) {
      return `state-${stateName.replace(/\s+/g, '-').toLowerCase()}`;
    }
    return "state-default";
  };

  return (
    <div>
      <div className="GemChapter-bkg">
        <div className="about-main-div">
          <div className="about-btn">
            <a href="" className="about-btn-link">
              Home <span className="separator"> &gt; </span> GEM Chapters
            </a>
          </div>
        </div>

        <div className="main-div-of-map">
          <div className="map-main-div">
            <div className="btn-div-map">
              {!selectedState && (
                <div className="state-buttons">
                  <h2 className="chapter-heading">GEM Council and State Chapters</h2>
                  <div className="state-buttons-container">
                    {Object.keys(stateData).map((state, index) => (
                      <button
                        key={state}
                        className={`state-btn state-btn-${index + 1}`} // Adding unique class based on index
                        onClick={() => onButtonClick(state)} // Button click triggers the same animation
                      >
                        {state}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {selectedState && (
                <div className="map-detail-container">
                  <div className="map-cardHeading">
                    <h3 className="state-name">{selectedState.name}</h3>
                  </div>
                  <div className="card-map-main">
                    <div className="map-detail-card">
                      <img src={selectedState.chairMan} alt="Chair Man" className="map-detail-img" />
                      <p className="ecoList">{selectedState.chairManName}</p>
                      <p className="ecoList">Chair</p>
                    </div>

                    <div className="map-detail-card">
                      <img src={selectedState.coChairMan} alt="Co-Chair Man" className="map-detail-img" />
                      <p className="ecoList">{selectedState.coChairManName}</p>
                      <p className="ecoList">Co-Chair</p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="map-container">
              <SVGMap
                map={India}
                onLocationClick={onLocationClick}
                onLocationMouseOver={onLocationHover}
                onLocationMouseOut={onLocationLeave}
                locationClassName={locationClassName} // Apply dynamic class
              />

              {hoveredState && (
                <div className="hover-info" style={{ left: hoverPosition.x, top: hoverPosition.y }}>
                  {hoveredState}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewGeoEcoSystem;