// import React, { useState } from 'react';
// import './EcoProductGrid.css';
// import EcoImg1 from '../assets/EcoProduct/EcoProduct-1.jpg';
// import EcoImg2 from '../assets/EcoProduct/EcoProduct-2.jpg';
// import EcoImg3 from '../assets/EcoProduct/EcoProduct-3.jpg';
// import EcoImg4 from '../assets/EcoProduct/EcoProduct-4.jpg';
// import EcoImg5 from '../assets/EcoProduct/EcoProduct-5.jpg';
// import EcoImg6 from '../assets/EcoProduct/EcoProduct-6.jpg';
// import EcoImg7 from '../assets/EcoProduct/EcoProduct-7.jpg';
// import EcoImg8 from '../assets/EcoProduct/EcoProduct-8.jpg';
// import EcoImg9 from '../assets/EcoProduct/EcoProduct-9.jpg';
// import EcoImg10 from '../assets/EcoProduct/EcoProduct-10.jpg';
// import EcoImg11 from '../assets/EcoProduct/EcoProduct-11.jpg';
// import EcoImg12 from '../assets/EcoProduct/EcoProduct-12.jpg';
// import { Link } from 'react-router-dom';

// const EcoProduct = () => {
//     const products = [
//         { src: EcoImg1, alt: 'Eco Product 1' },
//         { src: EcoImg2, alt: 'Eco Product 2' },
//         { src: EcoImg3, alt: 'Eco Product 3' },
//         { src: EcoImg4, alt: 'Eco Product 4' },
//         { src: EcoImg5, alt: 'Eco Product 5' },
//         { src: EcoImg6, alt: 'Eco Product 6' },
//         { src: EcoImg7, alt: 'Eco Product 7' },
//         { src: EcoImg8, alt: 'Eco Product 8' },
//         { src: EcoImg9, alt: 'Eco Product 9' },
//         { src: EcoImg10, alt: 'Eco Product 10' },
//         { src: EcoImg11, alt: 'Eco Product 11' },
//         { src: EcoImg12, alt: 'Eco Product 12' },
//     ];

//     const itemsPerPage = 8; // Number of items to show per page
//     const [currentPage, setCurrentPage] = useState(1);
//     const totalPages = Math.ceil(products.length / itemsPerPage);

//     // Get the current items to display
//     const currentItems = products.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

//     const handlePageChange = (page) => {
//         setCurrentPage(page);
//     };

//     return (
//         <div>
//              <div className="about-btn">
//             <a href="" className="about-btn-link">
//            <Link to='/'>   Home </Link> <span className="separator"> &gt; </span> Gem Eco-Product
//             </a>
//           {/* </div> */}
         

//         </div>

//         <div className="Eco-product-text">
//             <p className="text-para-eco">
//             The <b> GEM Eco-product certification </b> offers product manufacturers a unique 
//             preposition for assessing their products being environmentally benign. The 
//             eco-product certification considers several factors such as the Life cycle 
//             assessment, energy savings, waste management and water conservation as 
//             benchmark for certifying a product as green or eco-friendly. In addition, to the 
//             above, the products are evaluated for their cost effectiveness, user friendliness 
//             and their resolve in aligning with global goals such as the SDGs. 
//             </p>
            
//             <p className="text-para-eco">
//             The ISO 14020 defines environmental labelling as: 
//             Environmental labels and declarations provide information about a product or 
//             service in terms of its overall environmental character, a specific environmental 
//             aspect, or any number of aspects. 
//             </p>

//             <p className="text-para-eco">
//             The <b> GEM eco-product certification </b> goes beyond being just an environmental 
//             label. The certificate is a recognition for the products which have been 
//             evaluated based on sustainability principles of environmental, social and 
//             economic considerations. The GEM Eco-product certification values the efforts 
//             of companies in ensuring that a product undergoes thorough evaluation for its 
//             impacts during the life cycle. 
//             </p>

//             <p className="text-para-eco">
//             The certification considers a life cycle approach for evaluating products as eco-
//             labelling. 
//             </p>

//             <p className="text-para-eco">
//             The <b> ASSOCHAM GEM Eco-product certification </b> is intended to assist 
//             companies and organizations for enabling them to be conscious of their 
//             processes and management towards sustainability through changes which are 
//             aligned towards LiFE (Lifestyle for Environment) and circular economy 
//             concepts.  
//             </p>

//         </div>

//         <div className="grid-bkg-main">
//         <div className="grid-container">
           
//             <div className="grid">
//                 {currentItems.map((product, index) => (
//                     <div key={index} className="grid-item">
//                         <img src={product.src} alt={product.alt} className="grid-image" />
//                     </div>
//                 ))}
//             </div>
//             <div className="pagination">
//                 {Array.from({ length: totalPages }, (_, index) => (
//                     <button 
//                         key={index} 
//                         className={`page-button ${currentPage === index + 1 ? 'active' : ''}`} 
//                         onClick={() => handlePageChange(index + 1)}
//                     >
//                         {index + 1}
//                     </button>
//                 ))}
//             </div>
//         </div>
//         </div>
//         </div>
//     );
// };

// export default EcoProduct;


// new code
// import React from 'react';
// import Slider from 'react-slick';
// import './EcoProductGrid.css';
// import EcoImg1 from '../assets/EcoProduct/EcoProduct-1.jpg';
// import EcoImg2 from '../assets/EcoProduct/EcoProduct-2.jpg';
// import EcoImg3 from '../assets/EcoProduct/EcoProduct-3.jpg';
// import EcoImg4 from '../assets/EcoProduct/EcoProduct-4.jpg';
// import EcoImg5 from '../assets/EcoProduct/EcoProduct-5.jpg';
// import EcoImg6 from '../assets/EcoProduct/EcoProduct-6.jpg';
// import EcoImg7 from '../assets/EcoProduct/EcoProduct-7.jpg';
// import EcoImg8 from '../assets/EcoProduct/EcoProduct-8.jpg';
// import EcoImg9 from '../assets/EcoProduct/EcoProduct-9.jpg';
// import EcoImg10 from '../assets/EcoProduct/EcoProduct-10.jpg';
// import EcoImg11 from '../assets/EcoProduct/EcoProduct-11.jpg';
// import EcoImg12 from '../assets/EcoProduct/EcoProduct-12.jpg';
// import { Link } from 'react-router-dom';

// const EcoProduct = () => {
//     const products = [
//         { src: EcoImg1, alt: 'Eco Product 1' },
//         { src: EcoImg2, alt: 'Eco Product 2' },
//         { src: EcoImg3, alt: 'Eco Product 3' },
//         { src: EcoImg4, alt: 'Eco Product 4' },
//         { src: EcoImg5, alt: 'Eco Product 5' },
//         { src: EcoImg6, alt: 'Eco Product 6' },
//         { src: EcoImg7, alt: 'Eco Product 7' },
//         { src: EcoImg8, alt: 'Eco Product 8' },
//         { src: EcoImg9, alt: 'Eco Product 9' },
//         { src: EcoImg10, alt: 'Eco Product 10' },
//         { src: EcoImg11, alt: 'Eco Product 11' },
//         { src: EcoImg12, alt: 'Eco Product 12' },
//     ];

//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 4,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 3000,
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 3,
//                 }
//             },
//             {
//                 breakpoint: 768,
//                 settings: {
//                     slidesToShow: 2,
//                 }
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                 }
//             }
//         ]
//     };

//     return (
//         <div>
//             <div className="about-main-div">
//         {/* <div className="aboutButtonDiv"> */}
//           {/* <h2>About Us</h2> */}
//           <div className="about-btn">
//             <a href="" className="about-btn-link">
//               Home <span className="separator"> &gt; </span> Gem Eco-Product
//             </a>
//           {/* </div> */}
         
//         </div>
//       </div>

//             <div className="Eco-product-text">
//                 <p className="text-para-eco">
//                     The <b> GEM Eco-product certification </b> offers product manufacturers a unique 
//                     preposition for assessing their products being environmentally benign...
//                 </p>
//                 <p className="text-para-eco">
//                     The ISO 14020 defines environmental labelling as: 
//                     Environmental labels and declarations provide information about a product or 
//                     service in terms of its overall environmental character, a specific environmental 
//                     aspect, or any number of aspects. 
//                 </p>
//                 <p className="text-para-eco">
//                     The <b> GEM eco-product certification </b> goes beyond being just an environmental 
//                     label. The certificate is a recognition for the products which have been 
//                     evaluated based on sustainability principles of environmental, social and 
//                     economic considerations.
//                 </p>
//                 <p className="text-para-eco">
//                     The certification considers a life cycle approach for evaluating products as eco-
//                     labelling.
//                 </p>
//                 <p className="text-para-eco">
//                     The <b> ASSOCHAM GEM Eco-product certification </b> is intended to assist 
//                     companies and organizations for enabling them to be conscious of their 
//                     processes and management towards sustainability through changes which are 
//                     aligned towards LiFE (Lifestyle for Environment) and circular economy 
//                     concepts.  
//                 </p>
//             </div>

//             <div className="grid-bkg-main">
//                 <Slider {...settings}>
//                     {products.map((product, index) => (
//                         <div key={index} className="grid-item">
//                             {/* <Link to={`/product/${index + 1}`}> */}
//                                 <img src={product.src} alt={product.alt} className="grid-image" />
//                             {/* </Link> */}
//                         </div>
//                     ))}
//                 </Slider>
//             </div>
//         </div>
//     );
// };

// export default EcoProduct;


// new fresh code
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import './EcoProductGrid.css';
import EcoImg1 from '../assets/EcoProduct/EcoProduct-1.jpg';
import EcoImg2 from '../assets/EcoProduct/EcoProduct-2.jpg';
import EcoImg3 from '../assets/EcoProduct/EcoProduct-3.jpg';
import EcoImg4 from '../assets/EcoProduct/EcoProduct-4.jpg';
import EcoImg5 from '../assets/EcoProduct/EcoProduct-5.jpg';
import EcoImg6 from '../assets/EcoProduct/EcoProduct-6.jpg';
import EcoImg7 from '../assets/EcoProduct/EcoProduct-7.jpg';
import EcoImg8 from '../assets/EcoProduct/EcoProduct-8.jpg';
import EcoImg9 from '../assets/EcoProduct/EcoProduct-9.jpg';
import EcoImg10 from '../assets/EcoProduct/EcoProduct-10.jpg';
import EcoImg11 from '../assets/EcoProduct/EcoProduct-11.jpg';
import EcoImg12 from '../assets/EcoProduct/EcoProduct-12.jpg';
import { Link } from 'react-router-dom';

const EcoProduct = () => {
    const [animationClass, setAnimationClass] = useState('');
    const ecoProductTextRef = useRef(null);

    const products = [
        { src: EcoImg1, alt: 'Eco Product 1' },
        { src: EcoImg2, alt: 'Eco Product 2' },
        { src: EcoImg3, alt: 'Eco Product 3' },
        { src: EcoImg4, alt: 'Eco Product 4' },
        { src: EcoImg5, alt: 'Eco Product 5' },
        { src: EcoImg6, alt: 'Eco Product 6' },
        { src: EcoImg7, alt: 'Eco Product 7' },
        { src: EcoImg8, alt: 'Eco Product 8' },
        { src: EcoImg9, alt: 'Eco Product 9' },
        { src: EcoImg10, alt: 'Eco Product 10' },
        { src: EcoImg11, alt: 'Eco Product 11' },
        { src: EcoImg12, alt: 'Eco Product 12' },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setAnimationClass('fade-in');
                } else {
                    setAnimationClass('fade-out');
                }
            },
            { threshold: 0.1 }
        );

        if (ecoProductTextRef.current) {
            observer.observe(ecoProductTextRef.current);
        }

        return () => {
            if (ecoProductTextRef.current) {
                observer.unobserve(ecoProductTextRef.current);
            }
        };
    }, []);

    return (
        <div>
            <div className="Eco-product-bkg">
            <div className="about-main-div-eco">
                <div className="about-btn">
                    <a href="" className="about-btn-link">
                        Home <span className="separator"> &gt; </span> Gem Eco-Product
                    </a>
                </div>
            </div>

            <div ref={ecoProductTextRef} className={`Eco-product-text ${animationClass}`}>
                <p className="text-para-eco">
                    The <b>GEM Eco-product certification</b> offers product manufacturers a unique 
                    proposition for assessing their products as environmentally benign. This certification 
                    aims to promote the production of sustainable and eco-friendly products, allowing 
                    consumers to make informed choices that positively impact the environment.
                </p>
                <p className="text-para-eco">
                    The ISO 14020 defines environmental labeling as: Environmental labels and declarations 
                    provide information about a product or service in terms of its overall environmental 
                    character, a specific environmental aspect, or any number of aspects. This definition 
                    lays the groundwork for understanding how eco-labels can serve as powerful tools for 
                    transparency and consumer awareness.
                </p>
                <p className="text-para-eco">
                    The <b>GEM eco-product certification</b> goes beyond being just an environmental label. 
                    The certificate is a recognition for the products that have been evaluated based on 
                    sustainability principles that encompass environmental, social, and economic considerations. 
                    This multi-faceted approach ensures that certified products not only meet environmental standards 
                    but also contribute positively to society and the economy.
                </p>
                <p className="text-para-eco">
                    The certification considers a life cycle approach for evaluating products as eco-labeling, 
                    taking into account the environmental impact of a product from its conception to its disposal. 
                    This holistic view is essential for promoting truly sustainable practices within industries.
                </p>
                <p className="text-para-eco">
                    The <b>ASSOCHAM GEM Eco-product certification</b> is intended to assist companies and organizations 
                    in enabling them to be conscious of their processes and management towards sustainability. 
                    This involves changes aligned with LiFE (Lifestyle for Environment) and circular economy 
                    concepts, ultimately fostering a culture of responsibility and sustainability in business practices.
                </p>
            </div>

            <div className="grid-bkg-main">
                <Slider {...settings}>
                    {products.map((product, index) => (
                        <div key={index} className="grid-item">
                            <img src={product.src} alt={product.alt} className="grid-image" />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
        </div>
    );
};

export default EcoProduct;
