// Loading.js
import React from 'react';
import './Loading.css'; // Ensure to include styles
import LoadingImg from '../assets/logo-img1.png'; // Correct import path

const Loading = () => {
  return (
    <div className="loading-container">
      <img
        src={LoadingImg}
        alt="Loading..."
        className="loading-image"
      />
    </div>
  );
};

export default Loading;