import React from 'react';
import './GemRating.css';

// Gem data with arrays of images
const gemData = [
  { sno: 1, points: 'All essential requirements and 40 - 49 points', gemLevel: 'GEM 1', gemImages: ['https://green-assocham.com/property/ckfinder_img/images/diamond.png'] },
  { sno: 2, points: 'All essential requirements and 50 - 64 points', gemLevel: 'GEM 2', gemImages: ['https://green-assocham.com/property/ckfinder_img/images/diamond.png', 'https://green-assocham.com/property/ckfinder_img/images/diamond.png'] },
  { sno: 3, points: 'All essential requirements and 65 - 84 points', gemLevel: 'GEM 3', gemImages: ['https://green-assocham.com/property/ckfinder_img/images/diamond.png', 'https://green-assocham.com/property/ckfinder_img/images/diamond.png', 'https://green-assocham.com/property/ckfinder_img/images/diamond.png'] },
  { sno: 4, points: 'All essential requirements and 85 - 104 points', gemLevel: 'GEM 4', gemImages: ['https://green-assocham.com/property/ckfinder_img/images/diamond.png', 'https://green-assocham.com/property/ckfinder_img/images/diamond.png', 'https://green-assocham.com/property/ckfinder_img/images/diamond.png', 'https://green-assocham.com/property/ckfinder_img/images/diamond.png'] },
  { sno: 5, points: 'All essential requirements and 105 points or above', gemLevel: 'GEM 5', gemImages: ['https://green-assocham.com/property/ckfinder_img/images/diamond.png', 'https://green-assocham.com/property/ckfinder_img/images/diamond.png', 'https://green-assocham.com/property/ckfinder_img/images/diamond.png', 'https://green-assocham.com/property/ckfinder_img/images/diamond.png', 'https://green-assocham.com/property/ckfinder_img/images/diamond.png'] },
];

const GemRating = () => {
  return (
    <div className='rating-bkg'>
        <div className="about-main-div">
        <div className="about-btn">
          <a href="/" className="about-btn-link">
            Home <span className="separator"> &gt; </span> GEM Rating
          </a>
        </div>
      </div>

      <div className="gem-rating-para-div">
        <h3>About GEM Rating</h3>
        <p className="gem-rating-para">
        Sustainable buildings are playing a critical role in the development of many emerging economies. India’s Sustainable building market is estimated to double by 2022 at 10 billion sqft, valuing around USD 35-50 billion, driven by increasing awareness level, environmental benefits and government support.  India has emerged as one of the leading countries in terms of sustainable building projects and ranks only second after US in terms of number of green projects and built-up area. Still this is only about 5% of total buildings in India, and hence there is huge potential for further penetration of sustainable building design. 
        </p>

        <p className="gem-rating-para">
        In order to complement in India’s Sustainability Movement and take it to the next level, <b>ASSOCHAM </b>  has launched the “GEM Sustainability (Green) Certification Program” with the objective to promote environment friendly green building design and construction. GEM Sustainability Certification Rating Program is based upon BEE ECBC 2017 and NBC 2016. Through this initiative, ASSOCHAM do award the Sustainability Certification Rating to Housing, Urban Developments, Residential, Commercial, Hotels, College, Universities, Schools, Factory buildings and related developments.
        </p>
        </div>
        <div className="rating-para-div-2" id='rating-para-div-2'>
        <h3 id='rating-para-heading2'>All existing, new and upcoming buildings can participate in this program. </h3>
        <p className="gem-rating-para" id='rating-para-border'>
          <b>GEM Sustainability Certification Rating Program -</b><br />

          GEM Sustainability Certification Rating Program aims to address the sustainability of a given development throughout its lifecycle from design through construction to operation. <b>GEM Sustainability Certification Reference Guide</b> provides design guidance and detailed requirements for rating a project’s potential performance.
          </p>
        <p className="gem-rating-para" id='rating-para-border'>
        Project team can go for a <b>Certification of Intent</b> <b>(Pre-certification / Provisional Certification)</b> rating during pre-design, design or construction stages of the project and <b>Final Certification</b> rating when the building is complete.
          </p>
        <p className="gem-rating-para" id='rating-para-border'>
        <b>Pre-certification / Provisional Certification / Certification</b> of Intent rating will be awarded within 50 days from the date of the project registration with <b>ASSOCHAM</b> depending upon the construction stages of the project.
          </p>
        <p className="gem-rating-para" id='rating-para-border'>
       <b>ASSOCHAM is offering special onetime fee to the projects.</b>
          </p>
      </div>

      {/* rating-level */}

      <div className="rating-level-div" id='rating-para-div-2'>
        <h3 id='rating-para-heading2'>GEM Sustainability Certification Rating levels -</h3>
        <p className="gem-rating-para" id='rating-para-border'>
        <b>GEM Sustainability Certification</b> Rating is organized into <b>Thirty Principles</b> that are fundamental to a more sustainable development. There are some Essential and Suggested requirements of each Principle. Points are awarded for each Suggested requirement achieved.
          </p>
        <p className="gem-rating-para" id='rating-para-border'>
        There is a 0-135 point scale. Project will achieve <b>GEM 1 to GEM 5</b> rating levels as per the requirements fulfilled and scores achieved by the project. This depends upon the project design which includes building architectural and elevation design, materials used during construction, HVAC, lighting and plumbing system designs, water and energy consumption of the building.
          </p>
        <p className="gem-rating-para" id='rating-para-border'>
        To achieve a <b>GEM Sustainability Certification Rating</b>, all Essential Principle requirements must be fulfilled along with a minimum number of Principle points. <b>GEM 5</b> will be the highest achievable rating level in this program.
          </p>
      </div>

      {/* rating-level */}

        <div className="benefit-div" id='rating-para-div-2' >
        <h3 className='benefit-heading'><b>Key benefits of the certification –</b></h3>
        <ul class="benefits-list">
           
        <li class="benefit-item" id='rating-para-border'>Review of all design documents such as Architectural, Mechanical, Electrical, Plumbing, and Landscape by Sustainability experts for further value addition from a green building perspective.</li>
        <li class="benefit-item" id='rating-para-border'>Energy and water-efficient building design that will reduce the energy and water consumption of the building.</li>
        <li class="benefit-item" id='rating-para-border'>Design of a building that will utilize maximum daylight, fresh air, and provide a healthy environment to the building occupants.</li>
        <li class="benefit-item" id='rating-para-border'>Sustainability Certification rating will give additional marketing mileage to the projects over other conventional buildings.</li>
        </ul>

        </div>


    <div className="gem-rating">
      <h2 className='rating-table-heading'>Gem Rating Table</h2>

      {/* Gem Section: Displaying Image Paths */}
      <div className="gem-section">
        {/* <h3>Gem Levels and Their Images</h3> */}
        {/* <div className="gem-images">
          {gemData.map((gem, index) => (
            <div key={index} className="gem-item">
              <h4>{gem.gemLevel}</h4>
              <div className="gem-image-group">
                {gem.gemImages.map((image, idx) => (
                  <img
                    key={idx}
                    src={image}
                    alt={gem.gemLevel}
                    className="gem-image"
                  />
                ))}
              </div>
            </div>
          ))}
        </div> */}
      </div>

      {/* Table Section: Main Gem Rating Table */}
      <table className="gem-table">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Point Scored</th>
            <th>GEM Levels</th>
            <th>GEM</th>
          </tr>
        </thead>
        <tbody>
          {gemData.map((gem, index) => (
            <tr key={index}>
              <td>{gem.sno}</td>
              <td>{gem.points}</td>
              <td>{gem.gemLevel}</td>
              <td>
                <div className="gem-image-group">
                  {gem.gemImages.map((image, idx) => (
                    <img
                      key={idx}
                      src={image}
                      alt={gem.gemLevel}
                      className="gem-image"
                    />
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default GemRating;
