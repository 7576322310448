
import React from 'react';
import './AboutUs.css';
import VisionImg from '../assets/about-img/vision-2.jpg';
import MissionImg from '../assets/about-img/mission-img-1.jpg';

const chamberImages = [
  { src: "https://green-assocham.com/property/ckfinder_img/images/bmcci.gif", link: "https://bombaychamber.com/" },
  { src: "https://green-assocham.com/property/ckfinder_img/images/ccci.gif", link: "https://www.cochinchamber.org/" },
  { src: "https://green-assocham.com/property/ckfinder_img/images/imc.gif", link: "https://www.imcnet.org/" },
  { src: "https://green-assocham.com/property/ckfinder_img/images/mcci.gif", link: "https://madraschamber.in/" },
  { src: "https://green-assocham.com/property/ckfinder_img/images/phdcci.gif", link: "https://www.phdcci.in/" }
];

const AboutUs = () => {
  return (
    <div>
      <div className="about-main-div">
        <div className="about-btn">
          <a href="/" className="about-btn-link">
            Home <span className="separator"> &gt; </span> About Us
          </a>
        </div>
      </div>

      <div className="vision-main-div">
        <div className="vision-text-div">
          <h3>Vision</h3>
          <p className="vision-text-para">
            Empower Indian enterprise by inculcating knowledge that will be the catalyst of growth in the barrierless technology driven global market and help them upscale, align and emerge as formidable players in respective business segments.
          </p>
        </div>

        <div className="vision-img-div">
          <img id='vision-img' src={VisionImg} alt="Vision" />
        </div>
      </div>

      <div className="vision-main-div">
        <div className="vision-img-div">
          <img id='mission-img' src={MissionImg} alt="Mission" />
        </div>

        <div className="mission-text-div">
          <h3>Mission</h3>
          <p className="mission-text-para">
          As a representative organ of Corporate India, ASSOCHAM articulates the genuine, legitimate needs and interests of its members. Its mission is to impact the policy and legislative environment so as to foster balanced economic, industrial and social development. We believe education, IT, BT, Health, Corporate Social responsibility and environment to be the critical success factors.
          </p>
        </div>
      </div>

      <div className="about-paragraph-div">
        <h3>Members – Our Strength</h3>
        <p className='about-text-para'>
          ASSOCHAM represents the interests of more than 4,50,000 direct and indirect members across the country. Through its heterogeneous membership, ASSOCHAM combines the entrepreneurial spirit and business acumen of owners with management skills and expertise of professionals to set itself apart as a Chamber with a difference.
        </p>

        <p className='about-text-para'>
          Currently, ASSOCHAM has more than 100 National Councils covering the entire gamut of economic activities in India. It has been especially acknowledged as a significant voice of Indian industry in the field of Corporate Social Responsibility, Environment & Safety, HR & Labour Affairs, Corporate Governance, Information Technology, Biotechnology, Telecom, Banking & Finance, Company Law, Corporate Finance, Economic and International Affairs, Mergers & Acquisitions, Tourism, Civil Aviation, Infrastructure, Energy & Power, Education, Legal Reforms, Real Estate and Rural Development, Competency Building & Skill Development to mention a few.
        </p>
      </div>

      <div className="chamberMainDiv">
        <h2>Promoter Chambers</h2>
        <div className="chamberSubMain">
          {chamberImages.map((chamber, index) => (
            <div key={index} className={`chamber-img-${index + 1}`}>
              <a href={chamber.link} target="_blank" rel="noopener noreferrer">
                <img src={chamber.src} alt={`Chamber ${index + 1}`} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
