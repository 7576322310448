import React, { useState } from 'react';
import './NewResource1.css';
import Img1 from '../assets/Resource/NewBuilding.png';
import Img2 from '../assets/Resource/ExistingBuilding.png';
import Img3 from '../assets/Resource/GemHome.png';
import Img4 from '../assets/Resource/FactoryBuilding.png';
import Img5 from '../assets/Resource/wareHouse&Logistics.png';
import Img6 from '../assets/Resource/HealthCare.png';
import axios from 'axios';

const pdfResources = [
    { title: 'Download PDF 1', link: 'https://green-assocham.com/property/ckfinder_img/files/REFERENCE%20GUIDE%20(1).pdf', image: Img1 },
    { title: 'Download PDF 2', link: 'https://green-assocham.com/property/ckfinder_img/files/ASSOCHAM_GEM_EB_Reference_Guide_Oct_2018-Final.pdf', image: Img2 },
    { title: 'Download PDF 3', link: 'https://green-assocham.com/property/ckfinder_img/files/GEM_Sustainability_Certification_Program_Reference_Guide%20(2).pdf', image: Img3 },
    { title: 'Download PDF 4', link: 'https://green-assocham.com/property/ckfinder_img/files/GEM_Sustainability_Certification_Program_Reference_Guide%20(2).pdf', image: Img4 },
    { title: 'Download PDF 5', link: 'https://green-assocham.com/property/ckfinder_img/files/ASSOCHAM%20GEM%20Green%20Warehouse%20%26%20Logstics%20Campus%20-%20May%202021.pdf', image: Img5 },
    { title: 'Download PDF 6', link: 'https://green-assocham.com/property/ckfinder_img/files/GEM%20Sustainable%20Healthcare%20Buildings.pdf', image: Img6 },
];

const NewResource = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        designation: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Save user data to the database using Axios
            await axios.post(`${process.env.REACT_APP_API_URL}/api/userData`, formData);

            // Clear the form data
            setFormData({
                name: '',
                email: '',
                phone: '',
                designation: '',
                message: ''
            });

            // Download the selected PDF
            if (selectedPdf) {
                const link = document.createElement('a');
                link.href = selectedPdf;
                link.download = selectedPdf.split('/').pop();
                link.click();
                setModalOpen(false); // Close modal after submission
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to submit data. Please try again.');
        }
    };

    const handleDownloadClick = (link) => {
        setSelectedPdf(link);
        setModalOpen(true); // Open modal on button click
    };

    return (
        <div className="main-div-resource">
             <h2>Available Resources</h2>
        
        <div className="new-resource-container">
           

            <div className="resource-cards">
                {pdfResources.map((pdf, index) => (
                    <div key={index} className="resource-card">
                        <img src={pdf.image} alt={pdf.title} className="resource-banner" />
                        {/* <h3>{pdf.title}</h3> */}
                        <button className='Resource-btn' onClick={() => handleDownloadClick(pdf.link)}>Download</button>
                    </div>
                ))}
            </div>

            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
                        <h3>Register to Access PDF</h3>
                        <form onSubmit={handleSubmit}>
                            <input 
                                type="text" 
                                name="name" 
                                placeholder="Name" 
                                value={formData.name} 
                                onChange={handleChange} 
                                required 
                            />
                            <input 
                                type="email" 
                                name="email" 
                                placeholder="Email" 
                                value={formData.email} 
                                onChange={handleChange} 
                                required 
                            />
                            <input 
                                type="tel" 
                                name="phone" 
                                placeholder="Phone" 
                                value={formData.phone} 
                                onChange={handleChange} 
                                required 
                            />
                            <input 
                                type="text" 
                                name="designation" 
                                placeholder="Designation" 
                                value={formData.designation} 
                                onChange={handleChange} 
                                required 
                            />
                            <textarea 
                                name="message" 
                                placeholder="Message" 
                                value={formData.message} 
                                onChange={handleChange} 
                                required 
                            ></textarea>
                            <button type="submit">Register</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
        </div>
    );
};

export default NewResource;

// new code
// import React, { useState, useEffect } from 'react';
// import './NewResource.css';
// import pdf1 from '../assets/pdfFolder/demoPdf.pdf';
// import pdf2 from '../assets/slider-img-new1.jpeg';
// import pdf3 from '../assets/slider-img2.jpeg';
// import Img2 from '../assets/PdfBanner/bannerImg2.jpg';
// import Img4 from '../assets/PdfBanner/pdfBanner-2.webp';
// import Img5 from '../assets/PdfBanner/pdfBanner-3.webp';
// import axios from 'axios';

// const pdfResources = [
//     { title: 'Download PDF 1', link: pdf1, image: Img2 },
//     { title: 'Download PDF 2', link: pdf2, image: Img2 },
//     { title: 'Download PDF 3', link: pdf3, image: Img2 },
//     { title: 'Download PDF 4', link: pdf3, image: Img4 },
//     { title: 'Download PDF 5', link: pdf3, image: Img5 },
// ];

// const NewResource = () => {
//     const [modalOpen, setModalOpen] = useState(false);
//     const [selectedPdf, setSelectedPdf] = useState(null);
//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         phone: '',
//         designation: '',
//         message: ''
//     });
//     const [formError, setFormError] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [currentIndex, setCurrentIndex] = useState(0);
    
//     // Swipe variables
//     const [startX, setStartX] = useState(0);
//     const [isSwiping, setIsSwiping] = useState(false);

//     useEffect(() => {
//         const interval = setInterval(() => {
//             nextSlide();
//         }, 4000);
//         return () => clearInterval(interval);
//     }, [currentIndex]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setFormError('');

//         if (!formData.name || !formData.email || !formData.phone || !formData.designation || !formData.message) {
//             setFormError('All fields are required.');
//             return;
//         }

//         setLoading(true);

//         try {
//             await axios.post(`${process.env.REACT_APP_API_URL}/api/userData`, formData);

//             setFormData({
//                 name: '',
//                 email: '',
//                 phone: '',
//                 designation: '',
//                 message: ''
//             });

//             if (selectedPdf) {
//                 const link = document.createElement('a');
//                 link.href = selectedPdf;
//                 link.download = selectedPdf.split('/').pop();
//                 link.click();
//                 setModalOpen(false);
//             }
//         } catch (error) {
//             console.error('Error:', error);
//             setFormError('Failed to submit data. Please try again.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleDownloadClick = (link) => {
//         setSelectedPdf(link);
//         setModalOpen(true);
//     };

//     const nextSlide = () => {
//         setCurrentIndex((prevIndex) => (prevIndex + 1) % pdfResources.length);
//     };

//     const prevSlide = () => {
//         setCurrentIndex((prevIndex) => (prevIndex - 1 + pdfResources.length) % pdfResources.length);
//     };

//     const getDisplayedResources = () => {
//         return Array.from({ length: 3 }, (_, i) => pdfResources[(currentIndex + i) % pdfResources.length]);
//     };

//     // Swipe Event Handlers
//     const handleTouchStart = (e) => {
//         setStartX(e.touches[0].clientX);
//         setIsSwiping(true);
//     };

//     const handleTouchMove = (e) => {
//         if (!isSwiping) return;
//         const currentX = e.touches[0].clientX;
//         const diffX = startX - currentX;

//         if (diffX > 50) {
//             nextSlide();
//             setIsSwiping(false);
//         } else if (diffX < -50) {
//             prevSlide();
//             setIsSwiping(false);
//         }
//     };

//     const handleTouchEnd = () => {
//         setIsSwiping(false);
//     };

//     return (
//         <div 
//             className="new-resource-container" 
//             onTouchStart={handleTouchStart} 
//             onTouchMove={handleTouchMove} 
//             onTouchEnd={handleTouchEnd}
//         >
//             <h2>Available Resources</h2>

//             <div className="carousel-container">
//                 <button className="carousel-button left" onClick={prevSlide}>‹</button>
//                 <div className="resource-cards">
//                     {getDisplayedResources().map((pdf, index) => (
//                         <div key={index} className="resource-card">
//                             <img src={pdf.image} alt={pdf.title} className="resource-banner" />
//                             <h3>{pdf.title}</h3>
//                             <button onClick={() => handleDownloadClick(pdf.link)}>Download</button>
//                         </div>
//                     ))}
//                 </div>
//                 <button className="carousel-button right" onClick={nextSlide}>›</button>
//             </div>

//             {modalOpen && (
//                 <div className="modal" role="dialog" aria-labelledby="modal-title" aria-modal="true">
//                     <div className="modal-content">
//                         <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
//                         <h3 id="modal-title">Register to Access PDF</h3>
//                         <form onSubmit={handleSubmit}>
//                             {formError && <p className="error">{formError}</p>}
//                             <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
//                             <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
//                             <input type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />
//                             <input type="text" name="designation" placeholder="Designation" value={formData.designation} onChange={handleChange} required />
//                             <textarea name="message" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>
//                             <button type="submit" disabled={loading}>{loading ? 'Submitting...' : 'Register'}</button>
//                         </form>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default NewResource;
